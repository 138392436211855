<template>
    <div>
        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_323_325)">
                <path
                    d="M7.03 4.51L7.49 4.97L2.96 9.5H2.5V9.04L7.03 4.51ZM8.83 1.5C8.705 1.5 8.575 1.55 8.48 1.645L7.565 2.56L9.44 4.435L10.355 3.52C10.55 3.325 10.55 3.01 10.355 2.815L9.185 1.645C9.085 1.545 8.96 1.5 8.83 1.5ZM7.03 3.095L1.5 8.625V10.5H3.375L8.905 4.97L7.03 3.095Z"
                    fill="white" class="alterColor" />
            </g>
            <defs>
                <clipPath id="clip0_323_325">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>

    </div>
</template>
  
<script>

export default {
    name: "notiSwitch",
};
</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.alterColor{
fill:$clr-saina-light-dark-main-bg
}

</style>